import {
  Box,
  Center,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from '../../../store';
import Match from './Match';
import { SERVER_URL, officeTypeMatch } from '../../../utils/constants';
import Ranking from './Ranking';
import OverAll from './OverAll';
import { MatchInterface } from '../../../utils/types';
import StandAloneStandings from './StandAloneStandings';
import BlueIcon from '../../../assets/theme-icon.png';
import TopPlayers from '../TopPlayers';
import KnockoutStage from '../KnockoutStage';

export interface IOlympics {
  matches: MatchInterface[];
  hiddenSections?: string[];
}

export const returnName = (name: string) => {
  const splitted = name?.split('_');
  return splitted?.[splitted.length - 1];
};

export const returnImage = (url: string | undefined) => {
  return url ? SERVER_URL + url : BlueIcon;
};

const Olympics = ({ matches, hiddenSections }: IOlympics) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeIndex = (index: number) => setTabIndex(index);
  const { userModel: userActions } = useStoreActions((state) => state);
  const { userModel: userState } = useStoreState((state) => state);
  const { isDark } = userState;

  useEffect(() => {
    if (isDark) {
      userActions.setIsDark(false);
    }
  }, [isDark, userActions]);

  const topScorers = useMemo(() => {
    if (matches?.length) {
      let topScorer: any = {};
      for (const match of matches) {
        if (match?.actionDetails?.length) {
          for (const action of match?.actionDetails) {
            const teamName = action.team?.split(' ');

            const name = action.name + action.team;
            let basePlayer = {
              name: action.name,
              team: `${teamName[0]} ${teamName[1]} ${returnName(teamName[2])}`,
              goals: 0,
              assists: 0,
              photo: '',
              position: '',
              playerId: action.name + action.team,
            };
            if (action.type === 'Goal') {
              if (topScorer[name]) {
                topScorer[name].goals += 1;
              } else {
                topScorer[name] = { ...basePlayer, goals: 1 };
              }
            }
          }
        }
      }
      return Object.keys(topScorer)
        .map((key) => topScorer[key])
        .sort((a, b) => b.goals - a.goals);
    }
    return [];
  }, [matches]);

  return (
    <Box>
      <Box
        p={3}
        w="100%"
        maxW={'700px'}
        m={'auto'}
        position="relative"
        mt={'20px'}
      >
        <Tabs
          variant="line"
          colorScheme="green"
          onChange={handleChangeIndex}
          index={tabIndex}
          mt="20px"
        >
          <TabList>
            {!hiddenSections?.includes('Ranking') ? (
              <Tab
                fontSize={['sm', 'sm', 'md', 'md']}
                padding={['10px', '15px']}
              >
                {'Ranking'}
              </Tab>
            ) : null}
            <Tab fontSize={['sm', 'sm', 'md', 'md']} padding={['10px', '15px']}>
              {'Standings'}
            </Tab>
            <Tab fontSize={['sm', 'sm', 'md', 'md']} padding={['10px', '15px']}>
              <HStack>
                <Text>Live</Text>
                {matches?.filter((m) => m.isMatchLive).length > 0 && (
                  <Box
                    boxSize="20px"
                    rounded="full"
                    background="green"
                    fontSize="sm"
                    color="white"
                  >
                    {matches?.filter((m) => m.isMatchLive).length}
                  </Box>
                )}
              </HStack>
            </Tab>
            <Tab fontSize={['sm', 'sm', 'md', 'md']} padding={['10px', '15px']}>
              {'Matches'}
            </Tab>
            {!hiddenSections?.includes('Overall Points') ? (
              <Tab
                fontSize={['sm', 'sm', 'md', 'md']}
                padding={['10px', '15px']}
              >
                {'Overall Points'}
              </Tab>
            ) : null}

            {topScorers?.length ? (
              <Tab
                fontSize={['sm', 'sm', 'md', 'md']}
                padding={['10px', '15px']}
              >
                {'Top Scorers'}
              </Tab>
            ) : null}

            {matches?.filter((x) => x.stage !== 'Group Stage')?.length ? (
              <Tab
                fontSize={['sm', 'sm', 'md', 'md']}
                padding={['10px', '15px']}
              >
                {'Knockout'}
              </Tab>
            ) : null}
          </TabList>

          <TabPanels>
            {!hiddenSections?.includes('Ranking') ? (
              <TabPanel px="0">
                <Box>
                  {
                    <Ranking
                      matches={matches?.filter(
                        (m) => !m.stage.includes('Group')
                      )}
                    />
                  }
                </Box>
              </TabPanel>
            ) : null}
            <TabPanel px="0">
              <Box>
                {matches?.filter((m) => !officeTypeMatch.includes(m.type))
                  .length ? (
                  <StandAloneStandings
                    matches={matches?.filter(
                      (m) => !officeTypeMatch.includes(m.type)
                    )}
                    removeFP
                    isFull
                    removeLinks
                  />
                ) : (
                  <Center>No Standing Found</Center>
                )}
              </Box>
            </TabPanel>
            <TabPanel px="0">
              <Box>
                {matches?.filter((m) => m.isMatchLive).length ? (
                  matches
                    ?.filter((m) => m.isMatchLive)
                    .map((m) => <Match match={m} key={m.id + 'live'} addType />)
                ) : (
                  <Center>No Live Matches Found</Center>
                )}
              </Box>
            </TabPanel>
            <TabPanel px="0">
              <Box>
                {matches?.length ? (
                  matches?.map((m) => <Match match={m} key={m.id} />)
                ) : (
                  <Center>No Matches Found</Center>
                )}
              </Box>
            </TabPanel>
            {!hiddenSections?.includes('Overall Points') ? (
              <TabPanel px="0">
                <Box>
                  {matches?.length ? (
                    <OverAll matches={matches} />
                  ) : (
                    <Center>No Data Found</Center>
                  )}
                </Box>
              </TabPanel>
            ) : null}
            {topScorers?.length ? (
              <TabPanel px="0">
                <Box>
                  <TopPlayers topPlayers={topScorers} type="topScorer" />
                </Box>
              </TabPanel>
            ) : null}
            {matches?.filter(
              (m: MatchInterface) =>
                m.stage !== 'Group Stage' && !officeTypeMatch.includes(m.type)
            )?.length ? (
              <TabPanel px="0">
                <Box>
                  <KnockoutStage
                    matches={matches?.filter(
                      (m: MatchInterface) => m.stage !== 'Group Stage'
                    )}
                  />
                </Box>
              </TabPanel>
            ) : null}
            {/* <TabPanel px="0">
                  <Box>
                    {selectedCompData?.filter(
                      (m) =>
                        !m.stage.includes('Group') &&
                        !m.stage.includes('Third-place')
                    )?.length ? (
                      <Knockouts
                        matches={selectedCompData?.filter(
                          (m) =>
                            !m.stage.includes('Group') &&
                            !m.stage.includes('Third-place')
                        )}
                      />
                    ) : (
                      <Center>No Matches Found</Center>
                    )}
                  </Box>
                </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Olympics;
