import { Avatar, Box, HStack, Image, Tag, Text } from '@chakra-ui/react';
import { returnImage, returnName } from '.';
import {
  isSecondHalfStarted,
  returnDate,
  returnTimeAnyway,
} from '../../../utils/helper';
import { MatchInterface } from '../../../utils/types';

const Match = ({
  match,
  addType,
}: {
  match: MatchInterface;
  addType?: boolean;
}) => {
  return (
    <Box>
      {addType && (
        <Box textAlign={'center'}>
          <Tag size={'sm'} variant="solid" colorScheme="blue" mt="10px">
            {match.type}
          </Tag>
        </Box>
      )}
      <HStack
        alignItems="center"
        justifyContent="space-between"
        py="10px"
        borderBottom="1px"
        borderColor="gray.200"
        gap="0"
      >
        <Box
          display="flex"
          alignItems="flex-end"
          flexDirection="column"
          width={['125px', '40%']}
          cursor="pointer"
        >
          <HStack alignItems={'center'} justifyContent="flex-start">
            <Box fontSize={['sm', 'md']} textAlign="right">
              {returnName(match?.homeTeam.teams?.[0]?.name)}
            </Box>
            <Box
              rounded="full"
              bg={'white'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex="0 0 40px"
            >
              {match?.homeTeam?.teams?.[0]?.teamLogo?.url ? (
                <Image
                  src={returnImage(match?.homeTeam.teams?.[0]?.teamLogo?.url)}
                  boxSize={'40px'}
                  cursor="pointer"
                  rounded="full"
                  objectFit={'cover'}
                />
              ) : (
                <Avatar
                  boxSize="40px"
                  name={`${returnName(match?.homeTeam.teams?.[0]?.name)} `}
                  src={''}
                />
              )}
            </Box>
          </HStack>
        </Box>

        <Box width={['70px', '20%']} textAlign="center">
          <Text fontSize={['16px', '20px']} fontWeight="bold">
            {match.isMatchLive ||
            match.isMatchEnded ||
            isSecondHalfStarted(match.matchDate)
              ? `${match?.homeTeamScore || '0'} - ${
                  match?.awayTeamScore || '0'
                }`
              : returnDate(match.matchDate)}
          </Text>
          {match.isMatchLive ? (
            <Box>
              <Tag size={'sm'} variant="solid" colorScheme="green">
                Live
              </Tag>
            </Box>
          ) : match.isMatchEnded ? (
            <Box>
              <Tag size={'sm'} variant="solid" colorScheme="gray">
                Ended
              </Tag>
            </Box>
          ) : !match.matchDate ||
            isSecondHalfStarted(match.matchDate) ? null : (
            <Text as="span" fontSize={['13px', '16px']}>
              {returnTimeAnyway(match.matchDate)}
            </Text>
          )}
        </Box>

        <Box
          display="flex"
          cursor="pointer"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
          width={['125px', '40%']}
        >
          <HStack alignItems={'center'} justifyContent="flex-end">
            <Box
              rounded="full"
              bg={'white'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex="0 0 40px"
            >
              {match?.awayTeam.teams?.[0]?.teamLogo?.url ? (
                <Image
                  src={returnImage(match?.awayTeam.teams?.[0]?.teamLogo?.url)}
                  boxSize="40px"
                  rounded="full"
                  cursor="pointer"
                  objectFit={'cover'}
                />
              ) : (
                <Avatar
                  boxSize="40px"
                  name={`${returnName(match?.awayTeam.teams?.[0]?.name)} `}
                  src={''}
                />
              )}
            </Box>
            <Box fontSize={['sm', 'md']} textAlign="left">
              {returnName(match?.awayTeam.teams?.[0]?.name)}
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};

export default Match;
